export const queryContainerCrisp = () => {
  const containerCrisp = document.querySelector('#crisp-chatbox')
  return containerCrisp
}

export const buttonCrispContainerClass = 'container-button-crisp'

// getCrispChatIcon
export const getElementIconChatBox = (): Element => {
  const iconCrisp = document.querySelector('#crisp-chatbox div')
  if (!iconCrisp) return null

  const node = Array.from(iconCrisp?.childNodes?.values() ?? []).find(
    (childNode) => childNode?.nodeName?.toUpperCase() === 'A',
  )
  return node as HTMLDivElement
}

export const getElementContainerChatBox = (): Element => {
  const iconCrisp = document.querySelector('#crisp-chatbox>div')

  if (!iconCrisp) return null
  if (!iconCrisp.classList?.contains('container-crisp')) {
    iconCrisp.classList.add('container-crisp')
  }
  return iconCrisp
}

export const getCrisp = () => (typeof window !== 'undefined' && (window as any)?.$crisp) || null
