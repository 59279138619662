import { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AppState, useAppDispatch } from 'state'
import { initSystemData } from './actions'
import { fetchSystemData } from './calls/fetchSystemData'

export const useSystemData = () => {
  const system = useSelector((state: AppState) => state.system)
  const serverTimeMargin = useSelector((state: AppState) => state.app.serverTimeMargin) || 0

  const isServiceShutdown = useMemo(() => {
    if (system?.isEnableMaintenanceMode) {
      const rightJustNow = new Date().getTime() + serverTimeMargin
      const shutDownTime = system.serviceShutdownAt + serverTimeMargin
      const openTime = system.serviceOpenAt + serverTimeMargin

      if (rightJustNow > shutDownTime && rightJustNow < openTime) {
        return true
      }
      return false
    }
    return false
  }, [system, serverTimeMargin])

  return useMemo(
    () => ({
      serviceShutdownAt: system.serviceShutdownAt + (serverTimeMargin || 0),
      serviceOpenAt: system.serviceOpenAt + serverTimeMargin,
      isEnableMaintenanceMode:
        system.isEnableMaintenanceMode &&
        new Date().getTime() + serverTimeMargin < system.serviceOpenAt + serverTimeMargin,
      isServiceShutdown,
    }),
    [system, serverTimeMargin],
  )
}

export const usePollSystemData = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const fetch = async () => {
      const systemData = await fetchSystemData()
      dispatch(
        initSystemData({
          isEnableMaintenanceMode: systemData.isEnableMaintenanceMode,
          serviceOpenAt: systemData.openAt,
          serviceShutdownAt: systemData.shutDownAt,
        }),
      )
    }

    const interval = setInterval(fetch, 300000)

    return () => {
      clearInterval(interval)
    }
  }, [])
}
