import { useCallback, useMemo } from 'react'
import { buttonCrispContainerClass, getCrisp, getElementContainerChatBox, getElementIconChatBox } from 'utils/chatbox'
import { useVariableLoadedListener } from './useVariableLoadedListener'

export const IS_OPENED_CHAT = 'chat:opened'

export const OPEN_CHAT = 'chat:open'
export const CLOSE_CHAT = 'chat:close'

export const useCrisp = () => {
  const crisp = useVariableLoadedListener(() => {
    const crispWindow = getCrisp()
    return crispWindow
  })
  return useMemo(() => crisp, [crisp])
}

export const useToggleCrisp = () => {
  const crisp = useCrisp()
  const toggle = useCallback(() => {
    if (crisp) {
      if (crisp.is(IS_OPENED_CHAT)) crisp.push(['do', CLOSE_CHAT])
      else crisp.push(['do', OPEN_CHAT])
    }
  }, [crisp])

  return useMemo(() => toggle, [toggle])
}

export const useOpenCrisp = () => {
  // const crisp = useCrisp()
  // return useCallback(() => {
  //   if (crisp) crisp.push(['do', OPEN_CHAT])
  // }, [crisp])
}

export const useIconChatboxRendered = () => {
  const isRendered = useVariableLoadedListener(() => {
    const isRendered = typeof document !== 'undefined' && getElementIconChatBox()
    return isRendered
  })
  return useMemo(() => isRendered, [isRendered])
}

export const useElementContainerChatboxRendered = () => {
  const isRendered = useVariableLoadedListener(() => {
    const isRendered = typeof document !== 'undefined' && getElementContainerChatBox()
    return isRendered
  })

  return useMemo(() => isRendered, [isRendered])
}

export const useIconChatHandle = () => {
  const elementContainerChatbox = useElementContainerChatboxRendered()

  const handleVisibleIconChat = () => {
    if (
      !elementContainerChatbox?.classList?.contains(buttonCrispContainerClass) &&
      elementContainerChatbox?.classList
    ) {
      elementContainerChatbox?.classList.add(buttonCrispContainerClass)
    }
  }

  const handleHideIconChat = () => {
    if (!elementContainerChatbox?.classList) return

    elementContainerChatbox?.classList.remove(buttonCrispContainerClass)
  }

  return useMemo(
    () => ({
      visibleIconChat: handleVisibleIconChat,
      hideIconChat: handleHideIconChat,
    }),
    [handleVisibleIconChat, handleHideIconChat],
  )
}

