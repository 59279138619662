import { CashBackTransactionStatusEnums } from 'config/constants/cashback'
import { APIEndpointEnum } from 'config/constants/server'
import { CashbackTransaction } from 'config/types/cashback'
import { HunnyPlayRequest } from './HunnyPlayRequest'
import { BaseResponse, HunnyRequest, Paging } from './types'

class CashbackService extends HunnyPlayRequest {
  public getCashbackEndDate(): HunnyRequest<BaseResponse<{ timestamps: number[] }>> {
    const result = this._post(APIEndpointEnum.CashbackEndDate)

    return result
  }

  public getCashbackAvailableClaim(): HunnyRequest<BaseResponse<Paging<CashbackTransaction>>> {
    const result = this._post(APIEndpointEnum.CashbackTransaction, {
      paging: {
        offset: 0,
        limit: 1000,
      },
      status: CashBackTransactionStatusEnums.PENDING,
    })

    return result
  }

  public getCashbackTransactions(
    offset: number,
    limit: number,
  ): HunnyRequest<BaseResponse<Paging<CashbackTransaction>>> {
    const result = this._post(APIEndpointEnum.CashbackTransaction, {
      paging: {
        offset,
        limit,
      },
    })

    return result
  }

  public getCashbackTotalEarned(): HunnyRequest<BaseResponse<Paging<CashbackTransaction>>> {
    const result = this._post(APIEndpointEnum.CashbackTotalEarned)

    return result
  }

  public async claim(ids: number[]): Promise<BaseResponse<any>> {
    const result = this._request(APIEndpointEnum.CashbackClaim, { ids })
    return result
  }
}

const instance = new CashbackService()

export default instance
